import './style.css'
import * as THREE from 'three'
import { MeshBasicMaterial, ToneMapping } from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { AxesHelper } from 'three';

let camera, controls, scene, renderer, light, textRenderer



let group, group1, group2

let cube1, cube2, cube3, cube4, cube5, cube6, cube7, cube8, cube9, cube10, cube11, cube12

let texture1, texture2, texture3, texture4, texture5, texture6, texture7, texture8, texture9, texture10, texture11, texture12

let video1, video2, video3, video4, video5, video6, video7, video8, video9, video10, video11, video12

let cubetexture1, cubetexture2, cubetexture3, cubetexture4, cubetexture5, cubetexture6, cubetexture7, cubetexture8, cubetexture9, cubetexture10, cubetexture11, cubetexture12

let text1, text2, text3

let interactable = true

const name = document.getElementById('name')
const about = document.getElementById('about')
const contact = document.getElementById('contact')

const raycaster = new THREE.Raycaster()

const clock = new THREE.Clock();

let speed = 0.2

const canvas = document.querySelector('canvas.webgl')

const mouse = new THREE.Vector2()

const startButton = document.getElementById( 'startButton' );
startButton.addEventListener( 'click', init );

let currentIntersect = null




const applyRgbShader = (value, direction = 90) => {
    planeGeometries.map(card => {
      const material = card.material;
      material.uniforms.amount.value = value / 300.0;
      material.uniforms.angle.value = direction * ( Math.PI / 180 );
    });
  }


function init() {

    const overlay = document.getElementById( 'overlay' );
    overlay.remove();

    // Canvas

    // Scene
    scene = new THREE.Scene()

    // Text
    name.style.display = 'block'
    text1 = new CSS2DObject( name )
    text1.position.set( 0, 1.5 ,0 )
    scene.add( text1 )
    text1.layers.set( 0 )

    text2 = new CSS2DObject( about )
    text2.position.set( 0, 1 ,0 )
    scene.add( text2 )
    text2.layers.set( 0 )
    text2.visible = false

    text3 = new CSS2DObject( contact )
    text3.position.set( 0, 1 ,0 )
    scene.add( text3 )
    text3.layers.set( 0 )
    text3.visible = false


    textRenderer = new CSS2DRenderer();
    textRenderer.setSize( window.innerWidth, window.innerHeight );
    textRenderer.domElement.style.position = 'absolute';
    textRenderer.domElement.style.top = '0px';
    document.body.appendChild( textRenderer.domElement );

    //Video
    

    video1 = document.getElementById('IS');
    texture1 = new THREE.VideoTexture(video1);

    video2 = document.getElementById('LaocoonS');
    texture2 = new THREE.VideoTexture(video2);

    video3 = document.getElementById('pinColliderS');
    texture3 = new THREE.VideoTexture(video3);

    video4 = document.getElementById('SofieS');
    texture4 = new THREE.VideoTexture(video4);
    video4.play()

    video5 = document.getElementById('ladderS');
    texture5 = new THREE.VideoTexture(video5);
    video5.play()

    video6 = document.getElementById('studyS');
    texture6 = new THREE.VideoTexture(video6);
    video6.play()


    

    cubetexture1 = new THREE.MeshBasicMaterial({
        map: texture1,
        side: THREE.FrontSide,
        toneMapped: true
    })
    cubetexture2 = new THREE.MeshBasicMaterial({
        map: texture2,
        side: THREE.FrontSide,
        toneMapped: true
    })
    cubetexture3 = new THREE.MeshBasicMaterial({
        map: texture3,
        side: THREE.FrontSide,
        toneMapped: true
    })
    cubetexture4 = new THREE.MeshBasicMaterial({
        map: texture4,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture5 = new THREE.MeshBasicMaterial({
        map: texture5,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture6 = new THREE.MeshBasicMaterial({
        map: texture6,
        side: THREE.FrontSide,
        toneMapped: true
    })



    group = new THREE.Group()
    group.scale.y = 1
    scene.add(group)

    cube1 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture1
    )
    cube1.position.x = - 1.5
    video1.play()
    group.add(cube1)

    cube2 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture2
    )
    cube2.position.x = 0
    video2.play()
    group.add(cube2)

    cube3 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture3
    )
    cube3.position.x = 1.5
    video3.play()
    group.add(cube3)


    
    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    /**
     * Mouse
     */

    window.addEventListener('mousemove', (event) =>
    {
        mouse.x = event.clientX / sizes.width * 2 - 1
        mouse.y = - (event.clientY / sizes.height) * 2 + 1

    })

    name.addEventListener( 'pointerdown', () => {
        if(interactable==false){
            text1.position.set( 0, 1.5 ,0 )
            group.position.x = group.position.x + 0.75
            group.position.z = 0
            for (let i = 0; i < 4; i++) { 
                let myGroup = group1
                renderer.render(scene, camera);
                scene.remove(myGroup);
                let mesh = myGroup.children[i];
                mesh.geometry.dispose(); // FIX
                mesh.material.dispose(); // FIX
            }
            for (let i = 0; i < 4; i++) { 
                let myGroup = group2
                renderer.render(scene, camera);
                scene.remove(myGroup);
                let mesh = myGroup.children[i];
                mesh.geometry.dispose(); // FIX
                mesh.material.dispose(); // FIX
            }
            scene.remove(cube4)
            cube4.geometry.dispose(); // FIX
            cube4.material.dispose(); // FIX
            interactable = true
        }

    } );

    window.addEventListener('click', () =>
{
    if(currentIntersect)
    {
        switch(currentIntersect.object)
        {
            case cube1:
                speed = 0
                text1.position.y = -2
                name.textContent = 'Back'
                if(interactable == true){
                    interactable = false
                    displayAll()
                }
                break

            case cube2:
                group.visible = false
                text3.visible = false
                text2.visible = true
                break

            case cube3:
                group.visible = false
                text2.visible = false
                text3.visible = true
                break

            default:
                break
        }
    }
    else{
        group.visible = true
        text2.visible = false
        text3.visible = false
    }
})
    /**
     * Camera
     */
    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
    camera.position.z = 3

    /**
     * Renderer
     */
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        powerPreference: "high-performance" 
    })
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( sizes.width, sizes.height );
    document.body.appendChild( renderer.domElement );



    const listener = new THREE.AudioListener();
    camera.add( listener );

    scene.fog = new THREE.FogExp2( 0x000000, 0.0025 );

    light = new THREE.DirectionalLight( 0xffffff );
    light.position.set( 0, 0.5, 1 ).normalize();
    scene.add( light );

    const sphere = new THREE.SphereGeometry( 20, 32, 16 );


    // sound spheres


    const sound1 = new THREE.PositionalAudio( listener );
    const songElement = document.getElementById( 'song' );
    sound1.setMediaElementSource( songElement );
    sound1.setRefDistance( 20 );
    songElement.play();
    cube1.add( sound1 );



    //



    window.addEventListener( 'resize', onWindowResize );

    animate();

}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );
    textRenderer.setSize( window.innerWidth, window.innerHeight );

    controls.handleResize();

    window.addEventListener('mousemove', (event) =>
    {
        mouse.x = event.clientX / sizes.width * 2 - 1
        mouse.y = - (event.clientY / sizes.height) * 2 + 1

    })

}

function animate() {


    raycaster.setFromCamera(mouse, camera)
    
    const cubestest = [cube1, cube2, cube3]
    const intersects = raycaster.intersectObjects(cubestest)

    if(interactable == true){
        if(intersects.length)
        {
            if(!currentIntersect)
            {
                currentIntersect = intersects[0]
                switch(currentIntersect.object)
                {
                    case cube1:
                        speed = 2
                        cube3.material = cubetexture4
                        name.textContent = 'Works'
                        break
        
                    case cube2:
                        cube2.material = cubetexture6
                        name.textContent = 'About'
                        break
        
                    case cube3:
                        cube1.material = cubetexture5
                        name.textContent = 'Contact'
                        break

                }
            }
            }
            else
            {
            if(currentIntersect)
            {
                name.textContent = 'Ivy Fu'
                switch(currentIntersect.object)
                {
                    case cube1:
                        cube3.material = cubetexture1
                        speed = 0.2
        
                    case cube2:
                        cube2.material = cubetexture2
                        break
        
                    case cube3:
                        cube1.material = cubetexture3
                        break
                }
                currentIntersect = null
            }
            
        }
    }









    requestAnimationFrame( animate );
    render();

}


function render() {
    


    cube1.rotation.y = clock.getElapsedTime() * speed

    renderer.render( scene, camera );
    textRenderer.render( scene, camera );


}

function displayAll() {
    video7 = document.getElementById('netS');
    texture7 = new THREE.VideoTexture(video7);
    video7.play()

    video8 = document.getElementById('TraceS');
    texture8 = new THREE.VideoTexture(video8);
    video8.play()

    video9 = document.getElementById('clockS');
    texture9 = new THREE.VideoTexture(video9);
    video9.play()

    video10 = document.getElementById('morphS');
    texture10 = new THREE.VideoTexture(video10);
    video10.play()

    video11 = document.getElementById('plumbutterS');
    texture11 = new THREE.VideoTexture(video11);
    video11.play()
 
    video12 = document.getElementById('paperS');
    texture12 = new THREE.VideoTexture(video12);
    video12.play()

    
    cubetexture7 = new THREE.MeshBasicMaterial({
        map: texture7,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture8 = new THREE.MeshBasicMaterial({
        map: texture8,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture9 = new THREE.MeshBasicMaterial({
        map: texture9,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture10 = new THREE.MeshBasicMaterial({
        map: texture10,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture11 = new THREE.MeshBasicMaterial({
        map: texture11,
        side: THREE.FrontSide,
        toneMapped: true
    })

    cubetexture12 = new THREE.MeshBasicMaterial({
        map: texture12,
        side: THREE.FrontSide,
        toneMapped: true
    })
    cube1.material = cubetexture1
    cube2.material = cubetexture2
    cube3.material = cubetexture3
    cube4 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture4
    )
    cube4.position.x = cube3.position.x + 1.5 - 0.75
    cube4.position.z = -2
    scene.add(cube4)
    group.position.z = -2
    group.position.x = group.position.x - 0.75




    group1 = new THREE.Group()
    group1.scale.y = 1
    group1.position.y = 2
    scene.add(group1)
    group1.visble = false
    group1.position.z = -2

    cube5 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture5
    )
    cube5.position.x = -2.25
    group1.add(cube5)

    cube6 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture6
    )
    cube6.position.x = -0.75
    group1.add(cube6)

    cube7 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture7
    )
    cube7.position.x = 0.75
    group1.add(cube7)

    cube8 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture8
    )
    cube8.position.x = 2.25
    group1.add(cube8)

    group2 = new THREE.Group()
    group2.scale.y = 1
    group2.position.y = -2
    scene.add(group2)
    group2.visble = false
    group2.position.z = -2

    cube9 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture9
    )
    cube9.position.x = -2.25
    group2.add(cube9)

    cube10 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture10
    )
    cube10.position.x = -0.75
    group2.add(cube10)

    cube11 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture11
    )
    cube11.position.x = 0.75
    group2.add(cube11)


    cube12 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        cubetexture12
    )
    cube12.position.x = 2.25
    group2.add(cube12)

}



